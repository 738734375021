import React, { useEffect, useReducer, createContext } from 'react';
import PropTypes from 'prop-types';
import { appStatuses, fields, verifiedSectionKeys } from '../constants';
import { isCompanyInfoComplete, isContactInfoComplete, isDesignatedContactsSectionComplete, isPeopleComplete, isSecuritiesComplete, isServiceProvidersComplete } from '../utils/completes';
export const ProfileContext = createContext();

const initialState = {
  isLoaded: false,
  error: null,
  backupProfile: null,
  currentProfile: null,
  profile: null,
  readOnly: null,
  completeCards: {},
  enableVerifiedSections: null,
  verifiedProfile: null
};

const setEnabledVeifySections = profile => {
  const enableVerifiedSections = {
    [verifiedSectionKeys.COMPANY_INFO]: isCompanyInfoComplete(profile.companyInfo),
    [verifiedSectionKeys.CONTACT_INFO]: isContactInfoComplete(profile),
    [verifiedSectionKeys.PEOPLE]: isPeopleComplete(profile),
    [verifiedSectionKeys.SERVICE_PROVIDERS]: isServiceProvidersComplete(profile),
    [verifiedSectionKeys.DESIGNATE_CONTACTS]: isDesignatedContactsSectionComplete(profile),
    [verifiedSectionKeys.SECURITIES]: isSecuritiesComplete(profile.securityLists, profile.edgarFilingStatus, profile.companyTier)
  };

  return enableVerifiedSections;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROFILE':
      return {
        ...state,
        isLoaded: true,
        backupProfile: {...action.payload},
        profile: JSON.parse(JSON.stringify(action.payload)),
        readOnly: action.payload.status && action.payload.status.id && (action.payload.status.id === appStatuses.SUBMITTED || action.payload.status.id === appStatuses.PENDING_REVIEW),
        enableVerifiedSections: setEnabledVeifySections(action.payload)
      };
    case 'SET_VERIFIED_PROFILE':
      return {
        ...state,
        verifiedProfile: action.verifiedProfile,
        verifiedDate: action.verifiedDate
      };
    case 'UPDATE_PROFILE':
      const field = action.field;

      let updatedProfile = {
        ...state,
        profile: {
          ...state.profile,
          [field]: action.payload
        }
      };

      updatedProfile.enableVerifiedSections = setEnabledVeifySections(updatedProfile.profile);

      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.COMPANY_INFO]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.COMPANY_INFO] = false;
      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.CONTACT_INFO]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.CONTACT_INFO] = false;
      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.PEOPLE]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.PEOPLE] = false;
      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.SERVICE_PROVIDERS]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.SERVICE_PROVIDERS] = false;
      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.DESIGNATE_CONTACTS]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.DESIGNATE_CONTACTS] = false;
      if (!updatedProfile.enableVerifiedSections[verifiedSectionKeys.SECURITIES]) updatedProfile.profile.verifiedSections[verifiedSectionKeys.SECURITIES] = false;

      return updatedProfile;
    case 'SET_CURRENT_PROFILE':
      return {
        ...state,
        currentProfile: action.payload
      };
    default:
      throw new Error();
  }
};

export const ProfileContextProvider = ({ children }) => {
  const [profileState, dispatchProfile] = useReducer(reducer, initialState);

  return (
    <ProfileContext.Provider value={[profileState, dispatchProfile]}>
      {children}
    </ProfileContext.Provider>
  );
};

ProfileContextProvider.propTypes = {
    children: PropTypes.node
};
