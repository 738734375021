import React, { useState } from 'react';
import cn from 'classnames';
import { CheckBox } from 'devextreme-react';
import PropTypes from 'prop-types';
import { errors } from '../../constants';
import FieldLabel from '../FieldLabel';
import FieldError from '../FieldError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'devextreme-react/tooltip';
import styles from './CheckBoxField.module.scss';

const CheckBoxField = ({ ...props }) => {
  const [isFocus, setFocus] = useState(false);
  const [isFocusToolTip, setFocusToolTip] = useState(false);
  const moreInfo= props.moreInfo

  const handleMouseEnter = () => {
    props.isRequired && setFocus(true);
  };

  const handleMouseLeave = () => {
    props.isRequired && setFocus(false);
  };

  const handleMouseToggle = () => {
    setFocusToolTip(!isFocusToolTip);
  };

  const renderToolTip = () => {
    const labelId = `${props.text.replace(/\s+/g, '-').toLowerCase()}${Math.floor(Math.random() * 90000) + 10000}`;
    return moreInfo && <>
      <FontAwesomeIcon
        id={labelId}
        className={cn(styles.icon, styles.moreInfo)}
        icon={['far', 'circle-info']}
        onMouseEnter={handleMouseToggle}
        onMouseLeave={handleMouseToggle} />
      <Tooltip
        target={`#${labelId}`}
        visible={isFocusToolTip}
        position='bottom'
        maxWidth='375px'
        hideOnOutsideClick={false}
      >
        <div>{moreInfo}</div>
      </Tooltip>
    </>
  };



  return props.readOnly ? <div>
    <FieldLabel text={props.text} />
    {renderToolTip()}
    <div className='mtSm'>
      {props.value ? 'Yes' : 'No'}
    </div>
  </div>: <div className={cn(styles.container, props.className, {
    'small': props.size === 'small'
  })}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}>
    {(!props.value && props.isRequired) && <FieldError className={styles.error} isFocus={isFocus} error={props.errorText || errors.INCOMPLETE_DATA_FIELD} />}
    <CheckBox
      value={props.value || false}
      onValueChanged={props.onValueChanged}
      text={props.text}
      readOnly={props.readOnly}
      disabled={props.disabled} />
    {props.children && <div className={cn(styles.children, {
      [styles.disabled]: props.disabled
    })}>
      {props.children}
    </div>}
    {renderToolTip()}
  </div>;
};

CheckBoxField.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  errorText: PropTypes.string,
  size: PropTypes.oneOf(['normal', 'small']),
  children: PropTypes.node,
  readOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  moreInfo: PropTypes.string,
  onValueChanged: PropTypes.func
};

CheckBoxField.defaultProps = {
  size: 'normal'
};

export default CheckBoxField;
