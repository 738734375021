import { isPhoneWithCountry, isPhone, isEmail, isNumber, isBoolean, isUSA, isCanada, isAus, isWebsite } from './validations';
import { fields, reportingStandards, errors } from '../constants';
import { providers } from '../constants/forms/serviceProviders';
import { isTierOTCQB, isTierOTCQX, isTierPink, isDateWithinLast90Days, isDateWithinLastOneYear, isFutureDate, isSharesOutstandingLessThanAuthorized } from './helper';

export const isRequiredCountry = country => {
    return isUSA(country) || isCanada(country) || isAus(country) ? true : false;
};

export const isRequiredContactWebsite = profile => {
    const tier = profile.companyTier;
    return isTierOTCQB(tier) || isTierOTCQX(tier) ? true : false;
};

export const companyWebsiteComplete = profile => {
    if (isRequiredContactWebsite(profile)) {
        const contactInfo = profile.contactInfo;
        return contactInfo.basicContactInfo.website && isWebsite(contactInfo.basicContactInfo.website);
    }
    else {
        return true;
    }
};

export const isIncCorpHistNotEmpty = data => {
    return data.companyInfo && data.companyInfo.incorporationInfo && data.companyInfo.incorporationInfo.filter(item => !item.isDeleted).length > 0 ? true : false;
};

export const isIncCorpHistValid = data => {

    if (isIncCorpHistNotEmpty(data)) {
        return incorporationInfoListComplete(data.companyInfo.incorporationInfo);
    }
    else {
        return false;
    }
};

export const getIncCorpHistInvalidText = data => {
    if (isIncCorpHistNotEmpty(data)) {
        return errors.INC_HISTORY_INVALID;
    }
    else {
        return errors.INC_HISTORY_EMPTY;
    }
}

export const isOfficerComplete = person => person.isOfficer &&
    person.firstName &&
    person.lastName &&
    person.title1 ? true : false;

export const isDirectorComplete = person => person.isDirector &&
    person.firstName &&
    person.lastName &&
    isBoolean(person.isIndependent) ? true : false;

export const isIndividualComplete = person => !person.isCorpEntity && person.firstName && person.lastName ? true : false;

export const isDesignatedContactsSectionComplete = profile => {

    if (!isPrimaryContactsTabComplete(profile)) return false;
    if (!isAuthUsersTabComplete(profile)) return false;
    if (!isBillingContactsTabComplete(profile)) return false;
    return true;
};

export const isDesignatedContactComplete = (contact, PARAM) => {
    const isContactInfo = (contact.phone && contact.phoneCountryCode && isPhoneWithCountry(contact.phone, contact.phoneCountryCode)) && (contact.email && isEmail(contact.email)) ? true : false;
    const isAccountPayable = contact.firstName === 'Accounts' && contact.lastName === 'Payable' && isContactInfo ? true : false;
    const isValidOfficerDirector = (contact.isOfficer || contact.isDirector) && isContactInfo ? true : false;
    const isValidContact = contact[PARAM] && contact.firstName && contact.lastName && isContactInfo && contact.title1 ? true : false;
    
    if (PARAM === 'isOtherBillingContact' && contact.isAccountPayable && isContactInfo) return true;
    
    return isValidOfficerDirector || isValidContact || isAccountPayable;
};

export const checkBeneficialOwners = owner => {
    const isComplete = owner && owner.firstName && owner.lastName ? true : false;

    return isComplete;
};

export const isCorporateEntityComplete = item => {
    return item.corpEntity &&
    (item.hasBeneficialOwners && (item.hasBeneficialOwners && checkBeneficialOwners(item)))
    || (!item.hasBeneficialOwners && item.reasonForNone) ? true : false;
};

export const isBeneficialOwnerComplete = person => person && person.firstName && person.lastName ? true : false;

export const isServiceProviderComplete = (provider, typeId) => {
    const hasName = provider && provider.name && provider.name.trim().length > 0 ? true : false;
    if (typeId === '6') {
        return provider.isOwnTransferAgent || hasName;

    } else if (typeId === '3' && provider.noOutsideCounsel) {
        return true;
    } else {
        return provider && hasName;
    }
};

const incorporationInfoListComplete = list => {
    const listTotal = list.length;
    let listCompleteTotal = 0;

    list.forEach(item => {
        if (item.countryOfIncorporation && isRequiredCountry(item.countryOfIncorporation) && item.stateOfIncorporation && item.yearOfIncorporation) {
            listCompleteTotal++;
        } else if (item.countryOfIncorporation && !isRequiredCountry(item.countryOfIncorporation) && item.yearOfIncorporation) {
            listCompleteTotal++;
        }
    });

    return listTotal === listCompleteTotal;
};

export const isCompanyInfoComplete = companyInfo => {
    const isComplete = companyInfo.businessDesc && companyInfo.facilitiesDesc && companyInfo.productServiceDesc
        && companyInfo.incorporationInfo && companyInfo.incorporationInfo.length > 0 && incorporationInfoListComplete(companyInfo.incorporationInfo)
        && isNumber(companyInfo.numberOfEmployees) && companyInfo.numberOfEmployeesAsOf && companyInfo.filingInfo.fiscalYearEndMonth && companyInfo.filingInfo.fiscalYearEndDay ? true : false;
    
    return isComplete;
};

export const isAddressComplete = address => address.address1 && address.countryId && (isRequiredCountry(address.countryId) ? address.state : true) && address.city && address.zip ? true : false;

export const isContactInfoComplete = profile => {
    const contactInfo = profile.contactInfo;
    const isComplete = contactInfo.basicContactInfo.phone && isPhone(contactInfo.basicContactInfo.phone) && contactInfo.basicContactInfo.email && isEmail(contactInfo.basicContactInfo.email) && companyWebsiteComplete(profile)
     && (contactInfo.address && isAddressComplete(contactInfo.address)) && ((contactInfo.billingAddress && isAddressComplete(contactInfo.billingAddress)) || contactInfo.address.sameBillingAddress) && ((contactInfo.executiveAddress && isAddressComplete(contactInfo.executiveAddress)) || contactInfo.address.sameExecAddress) ? true : false;

    return isComplete;
};

export const isPeopleComplete = (profile = {} ) => {

    if (!isOfficersContactsTabComplete(profile)) return false;
    if (!isDirectorsTabComplete(profile)) return false;
    if (!isControlPersonsTabComplete(profile)) return false;
    return true;
};

export const isServiceProvidersComplete = data => {
    let isComplete = true;

    providers.forEach(provider => {
        const providerList =  data[provider.key].filter(p => !p.isDeleted);
        const isProviderRequired = typeof provider.isRequired === 'function' ? provider.isRequired(data) : provider.isRequired;
        if (isProviderRequired && (!providerList || providerList.length === 0)) isComplete = false;
        providerList.forEach(item => {
            if (!isServiceProviderComplete(item, provider.typeId)) isComplete = false;
        });
    });

    return isComplete;
};

export const isSecurityComplete = (security, edgarFilingStatus, companyTier) => {
    const isNotTierPink = !isTierPink(companyTier);
    const isNotIntlReporting = edgarFilingStatus && !edgarFilingStatus.includes(reportingStandards.INTERNATIONAL);
    const isNotUSBank = edgarFilingStatus && !edgarFilingStatus.includes(reportingStandards.BANK);
    const validRoundLotShareholdersData = isNumber(security.roundLotShareholders) && security.roundLotShareholdersDate && isDateWithinLastOneYear(security.roundLotShareholdersDate) && !isFutureDate(security.roundLotShareholdersDate) ? true : false;
    const validPublicFloatData = isNumber(security.publicFloat) && security.publicFloatAsOfDate && isDateWithinLastOneYear(security.publicFloatAsOfDate) && !isFutureDate(security.publicFloatAsOfDate) ? true : false;
    
    return (isNumber(security.authorizedShares) || security.unlimitedAuthorizedShares) && security.authorizedSharesAsOfDate && isDateWithinLast90Days(security.authorizedSharesAsOfDate) && !isFutureDate(security.authorizedSharesAsOfDate)
    && isNumber(security.outstandingShares) && isSharesOutstandingLessThanAuthorized(security)
    && security.outstandingSharesAsOfDate && isDateWithinLast90Days(security.outstandingSharesAsOfDate) && !isFutureDate(security.outstandingSharesAsOfDate)
    && ((isNotTierPink && isNotIntlReporting && isNotUSBank) ? validRoundLotShareholdersData : (isNumber(security.roundLotShareholders) || security.roundLotShareholdersDate) ? validRoundLotShareholdersData : true)
    && ((isNotTierPink && isNotIntlReporting && isNotUSBank) ? validPublicFloatData : (isNumber(security.publicFloat) || security.publicFloatAsOfDate) ? validPublicFloatData : true) ? true : false;
};

export const isSecuritySlideValid = (security, edgarFilingStatus, companyTier) => {
    const isNotTierPink = !isTierPink(companyTier);
    const isNotIntlReporting = edgarFilingStatus && !edgarFilingStatus.includes(reportingStandards.INTERNATIONAL);
    const isNotUSBank = edgarFilingStatus && !edgarFilingStatus.includes(reportingStandards.BANK);
    const validRoundLotShareholdersData = isNumber(security.roundLotShareholders) && security.roundLotShareholdersDate ? true : false;
    const validPublicFloatData = isNumber(security.publicFloat) && security.publicFloatAsOfDate ? true : false;
    
    return (isNumber(security.authorizedShares) || security.unlimitedAuthorizedShares) && security.authorizedSharesAsOfDate
    && isNumber(security.outstandingShares) && isSharesOutstandingLessThanAuthorized(security)
    && security.outstandingSharesAsOfDate
    && ((isNotTierPink && isNotIntlReporting && isNotUSBank) ? validRoundLotShareholdersData : (isNumber(security.roundLotShareholders) || security.roundLotShareholdersDate) ? validRoundLotShareholdersData : true)
    && ((isNotTierPink && isNotIntlReporting && isNotUSBank) ? validPublicFloatData : (isNumber(security.publicFloat) || security.publicFloatAsOfDate) ? validPublicFloatData : true) ? true : false;
};

export const isSecuritiesComplete = (securities, edgarFilingStatus, companyTier) => {
    let isComplete = true;

    securities.forEach(sec => {
        if (!isSecurityComplete(sec, edgarFilingStatus, companyTier)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

// tabs logic

export const isOfficersContactsTabComplete = (profile = {}) => {
    const peopleList = profile.peopleList || [];
    const officers = peopleList.filter(person => person[fields.OFFICER] && !person.isDeleted);

    if (!officers || officers.length === 0) return false;

    let isComplete = true;

    officers.forEach(person => {
        if (!isOfficerComplete(person)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

export const isDirectorsTabComplete = (profile = {}) => {
    const peopleList = profile.peopleList || [];
    const tier = profile.companyTier;
    const directors = peopleList.filter(person => person[fields.DIRECTOR] && !person.isDeleted);

    let isComplete = true;

    directors.forEach(person => {
        if (!isDirectorComplete(person)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};


export const isControlPersonsTabComplete = (profile = {}) => {
    const controlPersons = profile.controlPersons;
    const controlPersonList = controlPersons.filter(person => !person.isDeleted);

    if (!profile.noControlPerson && (!controlPersonList || controlPersonList.length === 0)) return false;

    let isComplete = true;

    controlPersonList.forEach(person => {
        if (!isIndividualComplete(person) && !isCorporateEntityComplete(person)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

export const isPrimaryContactsTabComplete = (profile = {}) => {
    const peopleList = profile.peopleList || [];
    const primaryContacts = peopleList.filter(person => person[fields.PRIMARY_CONTACT] && !person.isDeleted);

    if (!primaryContacts || primaryContacts.length === 0) return false;

    let isComplete = true;

    primaryContacts.forEach(person => {
        if (!isDesignatedContactComplete(person, fields.PRIMARY_CONTACT)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

export const isAuthUsersTabComplete = (profile = {}) => {
    const peopleList = profile.peopleList || [];
    const authUsers = peopleList.filter(person => person[fields.AUTH_USER] && !person.isDeleted);

    if (!authUsers || authUsers.length === 0) return false;

    let isComplete = true;

    authUsers.forEach(person => {
        if (!isDesignatedContactComplete(person, fields.AUTH_USER)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

export const isBillingContactsTabComplete = (profile = {}) => {
    const peopleList = profile.peopleList || [];
    const primaryBillingUsers = peopleList.filter(person => person[fields.PRIMARY_BILLING] && !person.isDeleted);

    if (!primaryBillingUsers || primaryBillingUsers.length === 0) return false;

    let isComplete = true;

    peopleList.forEach(person => {
        if (person[fields.PRIMARY_BILLING] && !isDesignatedContactComplete(person, fields.PRIMARY_BILLING)) {
            isComplete = false;
            return;
        }

        if (person[fields.OTHER_BILLING] && !isDesignatedContactComplete(person, fields.OTHER_BILLING)) {
            isComplete = false;
            return;
        }
    });

    return isComplete;
};

