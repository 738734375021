import _ from 'lodash';
import moment from 'moment-timezone';
import { getCountryName, getStateName } from '../locale';
import { compareField } from '../helper';
import { compareFieldTypes } from '../../constants';

export const getCompanyInfoCompare = (currentProfile, updatedProfile) => {
    const json = [];
    const currentCompanyInfo = currentProfile.companyInfo;
    const updatedCompanyInfo = updatedProfile.companyInfo;

    // *General Information
    const generalInfo = {
        header: 'General Information',
        fields: []
    };

    compareField(currentCompanyInfo, updatedCompanyInfo, 'businessDesc', 'Business Description', generalInfo);
    compareField(currentCompanyInfo, updatedCompanyInfo, 'facilitiesDesc', 'Facilities Description', generalInfo);
    compareField(currentCompanyInfo, updatedCompanyInfo, 'productServiceDesc', 'Product & Services Description', generalInfo);
    compareField(currentCompanyInfo, updatedCompanyInfo, 'numberOfEmployees', '# of Employees', generalInfo, compareFieldTypes.NUMBER);
    compareField(currentCompanyInfo, updatedCompanyInfo, 'numberOfEmployeesAsOf', '# of Employees (As of)', generalInfo, compareFieldTypes.DATE);

    if (generalInfo.fields && generalInfo.fields.length > 0) json.push(generalInfo);

    // *Incorp History

    // Check for new Inc His
    updatedCompanyInfo.incorporationInfo && updatedCompanyInfo.incorporationInfo.forEach(item => {
        const updatedIncorp = item;

        if (!currentCompanyInfo.incorporationInfo.find(current => current.id === updatedIncorp.id)) {
            const incorpHistory = {
                header: `Incorporation History`,
                isNew: true,
                fields: []
            };
            
            incorpHistory.fields.push({
                fieldName: 'Country of Incorporation',
                oldValue: null,
                newValue: getCountryName(updatedIncorp.countryOfIncorporation)
            });
    
            incorpHistory.fields.push({
                fieldName: 'State/Province of Incorporation',
                oldValue: null,
                newValue: getStateName(updatedIncorp.stateOfIncorporation, updatedIncorp.countryOfIncorporation)
            });
    
            incorpHistory.fields.push({
                fieldName: 'Year of Incorporation',
                oldValue: null,
                newValue: updatedIncorp.yearOfIncorporation
            });
    
            json.push(incorpHistory);
        }
    });

    // Check for Updated Inc His
    currentCompanyInfo.incorporationInfo && currentCompanyInfo.incorporationInfo.forEach((item, i) => {
        const currentIncorp = item;
        const updatedIncorp = updatedCompanyInfo.incorporationInfo.find(incorp => incorp.id === currentIncorp.id);

        if (!updatedIncorp) return;

        const incorpHistory = {
            header: `Incorporation History - ${getCountryName(currentIncorp.countryOfIncorporation)}${currentIncorp.stateOfIncorporation ? ' ' + getStateName(currentIncorp.stateOfIncorporation, currentIncorp.countryOfIncorporation) : ''} ${currentIncorp.yearOfIncorporation}`,
            fields: []
        };

        if ((!!currentIncorp.countryOfIncorporation || !!updatedIncorp.countryOfIncorporation) && currentIncorp.countryOfIncorporation !== updatedIncorp.countryOfIncorporation) incorpHistory.fields.push({
            fieldName: 'Country of Incorporation',
            oldValue: getCountryName(currentIncorp.countryOfIncorporation),
            newValue: getCountryName(updatedIncorp.countryOfIncorporation)
        });

        if ((!!currentIncorp.stateOfIncorporation || !!updatedIncorp.stateOfIncorporation) && currentIncorp.stateOfIncorporation !== updatedIncorp.stateOfIncorporation) incorpHistory.fields.push({
            fieldName: 'State/Province of Incorporation',
            oldValue: getStateName(currentIncorp.stateOfIncorporation, updatedIncorp.countryOfIncorporation),
            newValue: getStateName(updatedIncorp.stateOfIncorporation, updatedIncorp.countryOfIncorporation)
        });

        if ((!!currentIncorp.yearOfIncorporation || !!updatedIncorp.yearOfIncorporation) && currentIncorp.yearOfIncorporation !== updatedIncorp.yearOfIncorporation) incorpHistory.fields.push({
            fieldName: 'Year of Incorporation',
            oldValue: currentIncorp.yearOfIncorporation,
            newValue: updatedIncorp.yearOfIncorporation
        });

        if (incorpHistory.fields && incorpHistory.fields.length > 0) json.push(incorpHistory);
    });

    // Check for Deleted Inc His
    currentCompanyInfo.incorporationInfo && currentCompanyInfo.incorporationInfo.forEach((item, i) => {
        const currentIncorp = item;

        if (!updatedCompanyInfo.incorporationInfo.find(current => current.id === item.id)) {
            const incorpHistory = {
                header: `Incorporation History`,
                isDeleted: true,
                fields: []
            };
    
            incorpHistory.fields.push({
                fieldName: 'Country of Incorporation',
                oldValue: getCountryName(currentIncorp.countryOfIncorporation),
                newValue: null
            });
    
            incorpHistory.fields.push({
                fieldName: 'State/Province of Incorporation',
                oldValue: getStateName(currentIncorp.stateOfIncorporation, currentIncorp.countryOfIncorporation),
                newValue: null
            });
    
            incorpHistory.fields.push({
                fieldName: 'Year of Incorporation',
                oldValue: currentIncorp.yearOfIncorporation,
                newValue: null
            });
    
            json.push(incorpHistory);
        }
    });

    // *Company Name History
    updatedCompanyInfo.companyNameHistoryList && updatedCompanyInfo.companyNameHistoryList.forEach((item, i) => {
        const updatedIncorp = item;
        
        if (!currentCompanyInfo.companyNameHistoryList || !currentCompanyInfo.companyNameHistoryList.find(current => current.id === updatedIncorp.id))  {
            const compNameHistory = {
                header: `Company Name History`,
                isNew: true,
                fields: []
            };
    
            compNameHistory.fields.push({
                fieldName: 'Previous Name',
                oldValue: null,
                newValue: updatedIncorp.fromName
            });
    
            compNameHistory.fields.push({
                fieldName: 'New Name',
                oldValue: null,
                newValue: updatedIncorp.toName
            });
    
            compNameHistory.fields.push({
                fieldName: 'Effective Date',
                oldValue: null,
                newValue: moment(updatedIncorp.date).format(compareFieldTypes.MMDDYYYYY)
            });
    
            json.push(compNameHistory);
        }
    });
    
    // *Filing Information
    const filingInfo = {
        header: 'Filing Information',
        fields: []
    };

    compareField(currentCompanyInfo.filingInfo, updatedCompanyInfo.filingInfo, 'fiscalYearEndMonth', 'Fiscal Year End Month', filingInfo);
    compareField(currentCompanyInfo.filingInfo, updatedCompanyInfo.filingInfo, 'fiscalYearEndDay', 'Fiscal Year End Day', filingInfo);
    compareField(currentCompanyInfo.filingInfo, updatedCompanyInfo.filingInfo, 'filingCycle', 'Filing Cycle', filingInfo);
    compareField(currentCompanyInfo.filingInfo, updatedCompanyInfo.filingInfo, 'primarySicCode', 'Primary SIC Code', filingInfo);
    compareField(currentCompanyInfo.filingInfo, updatedCompanyInfo.filingInfo, 'cik', 'SEC CIK Code', filingInfo);

    if (filingInfo.fields && filingInfo.fields.length > 0) json.push(filingInfo);

    // *Organizational Stage
    const organizationIndicators = {
        header: 'Organizational Stage',
        fields: []
    };
    
    compareField(currentCompanyInfo.organizationIndicators, updatedCompanyInfo.organizationIndicators, 'blankCheck', 'Blank Check', organizationIndicators, compareFieldTypes.BOOLEAN);
    compareField(currentCompanyInfo.organizationIndicators, updatedCompanyInfo.organizationIndicators, 'shell', 'Shell Company', organizationIndicators, compareFieldTypes.BOOLEAN);
    compareField(currentCompanyInfo.organizationIndicators, updatedCompanyInfo.organizationIndicators, 'spac', 'SPAC', organizationIndicators, compareFieldTypes.BOOLEAN);
    compareField(currentCompanyInfo.organizationIndicators, updatedCompanyInfo.organizationIndicators, 'blindPool', 'Blind Pool', organizationIndicators, compareFieldTypes.BOOLEAN);

    if (organizationIndicators.fields && organizationIndicators.fields.length > 0) json.push(organizationIndicators);

    // RETURN FINAL JSON COMPARISON
    return json;
};
