import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import equal from 'fast-deep-equal/es6';
import { withRouter } from '../../WithRouter';
import { ProfileContext } from '../../../context/Profile.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { entities, errors, routes, verifiedSectionKeys } from '../../../constants';
import { formSections } from '../../../constants/forms/securities';
import { isSecurityComplete, isSecuritySlideValid } from '../../../utils/completes';
import Controls from '../../Controls';
import EntityList from '../../EntityList';
import FormInstructions from '../../FormInstructions';
import VerifyBox from '../../VerifyBox';
import Button from '../../Button';
import FormSection from '../../FormSection';
import Label from '../../Label';
import SlidePanel from '../../SlidePanel';
import Title from '../../Title';
import ApplicationDescription from '../../ApplicationDescription/ApplicationDescription';
import styles from './Securities.module.scss';

const UNDERLYING_NOTE = 'If this underlying security information is not up-to-date, please make a note on the Review and Submit Page.';

const Securities = ({ params: { symbol }, saveApplication }) => {
  const [profileState, dispatchProfile] = useContext(ProfileContext);
  const [selectedSecurity, setSelectedSecurity] = useState(null);
  const [slidePanel, setSlidePanel] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const profileData = profileState.profile;
  const backupProfile = profileState.backupProfile;
  const securities = profileData && profileData.securityLists;
  const edgarFilingStatus = profileData.edgarFilingStatus;
  const companyTier = profileData.companyTier;
  const hasSingleFE = selectedSecurity && selectedSecurity.metadata && selectedSecurity.metadata.length === 1 && selectedSecurity.foreignExchangeSymbol && selectedSecurity.foreignExchangeName && selectedSecurity.metadata[0].symbol.length === 5 ? true : false;
  const hasMultipleFE = selectedSecurity && selectedSecurity.metadata && selectedSecurity.metadata.length > 1 && selectedSecurity.foreignExchangeSymbol && selectedSecurity.foreignExchangeName ? true : false;
  const isReadOnly = profileState.readOnly;

  const singleFeLabel = <>
    Provide information for the underlying Security {selectedSecurity && selectedSecurity.foreignExchangeSymbol} on {selectedSecurity && selectedSecurity.foreignExchangeName}.
    <br/ ><br/ >
    {UNDERLYING_NOTE}
  </>;
  
  const mulipleFeLabel = <>
    Both of these ticker symbols are linked to {selectedSecurity && selectedSecurity.foreignExchangeSymbol} on the {selectedSecurity && selectedSecurity.foreignExchangeName}. Please provide the share information below for {selectedSecurity && selectedSecurity.foreignExchangeSymbol}.
    <br/ ><br/ >
    {UNDERLYING_NOTE}
  </>;

  useEffect(() => {
    if (!slidePanel) {
      setValidationError(false);
    }
  }, [slidePanel]);

  useEffect(() => {
    const isSecuritiesChange = !equal({ ...backupProfile.securityLists }, { ...securities });

    if (isSecuritiesChange) {
      saveApplication();
    }
  }, [backupProfile.securityLists, securities, saveApplication]);

  const entityTitles = item => {
    let titles = [entities.NO_NAME];
    const securityTitles = item.metadata && item.metadata.map(metadata => {
      return `${metadata.symbol}/${metadata.classname}`;
    });

    if (securityTitles && securityTitles.length > 0) titles = securityTitles.join(', ');
    return titles;
  };

  const slideOutTitle = _ => {
    const titles = entityTitles(selectedSecurity);
    return titles;
  };

  const handleEdit = security => {
    setSelectedSecurity({ ...security });
    setSlidePanel(true);
  };

  const onValueChanged = updatedObj => {
    if (updatedObj.noParValue) updatedObj.parValue = null;
    if (updatedObj.unlimitedAuthorizedShares) updatedObj.authorizedShares = null;
    setSelectedSecurity(updatedObj);
  };

  const onUpdate = (data, field) => {
    dispatchProfile({
      type: 'UPDATE_PROFILE',
      payload: data,
      field: field
    });
  };

  const handleUpdate = _ => {
    setValidationError(false);

    let updateProvderList = profileData.securityLists;

    if (!isSecuritySlideValid(selectedSecurity, edgarFilingStatus, companyTier)) {
      setValidationError(true);
      return;
    };

    updateProvderList = updateProvderList.map(security => {
      if (security.metadata[0].symbol === selectedSecurity.metadata[0].symbol) return selectedSecurity;

      return security;
    });

    onUpdate(updateProvderList, 'securityLists');

    setSlidePanel(false);
  };

  return <div>
    <ApplicationDescription title='Securities' />
    <FormInstructions />
    <EntityList
        className='mbXL'
        list={securities}
        confirmRemoveText={entities.CONFIRM_REMOVE_PERSON}
        icon={'fileCert'}
        iconComplete={'fileCert'}
        entityTitles={entityTitles}
        validateComplete={data => isSecurityComplete(data, edgarFilingStatus, companyTier)}
        readOnly={isReadOnly}
        handleEntityClick={security => handleEdit(security)}
      />
    {!isReadOnly && <VerifyBox section={verifiedSectionKeys.SECURITIES} />}
    <Link to={`${routes.PROFILE_PREFIX}/${symbol}/${routes.REVIEW_SUBMIT}`}>
      <Button title='Proceed to Review and Submit' fullWidth />
    </Link>
    <SlidePanel 
        isOpen={slidePanel}
        onClose={() => setSlidePanel(false)}
        title={selectedSecurity && slideOutTitle()}>
          <div>
            {hasSingleFE && <Label className='mbLg' title={singleFeLabel} />}
            {hasMultipleFE && <Label className='mbLg' title={mulipleFeLabel} />}
            <Label>
              <FontAwesomeIcon className={styles.icon} icon={['fas', 'shield-halved']} />
              <Title className={styles.labelTitle} type='h3' title='Transfer Agent Verified' />
              <div>
                This icon denotes that this data has been recently provided by the Transfer Agent. Editing the information displayed here will remove the Transfer Agent Verified badge on this form, as well as on the Company Profile on otcmarkets.com
              </div>
            </Label>
            <div className='mtXL'>
              {formSections.map(section => <FormSection key={section.title} section={section} data={{ ...selectedSecurity, edgarFilingStatus, companyTier }} readOnly={isReadOnly} handleValueChange={onValueChanged} />)}
            </div>
          </div>
          {validationError && <Label isError title={errors.INCOMPLETE_DATA} />}
          {!isReadOnly && <Controls
            className='mtXXL'
            cancelText='Cancel'
            submitText='Update'
            onCancelClick={() => setSlidePanel(false)}
            onSubmitClick={handleUpdate}
          />}
      </SlidePanel>
  </div>;
};

Securities.propTypes = {
  params: PropTypes.shape({
    symbol: PropTypes.string
  }),
  saveApplication: PropTypes.func
};

export default withRouter(Securities);
