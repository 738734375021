import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import cn from 'classnames';
import { errors, fieldTypes } from '../../constants';
import Title from '../Title';
import TextBox from '../TextBox';
import TextBoxWithoutTrim from '../TextBox/TextBoxWithoutTrim';
import NumberBox from '../NumberBox';
import TextArea from '../TextArea';
import SelectBox from '../SelectBox';
import DynamicPhoneInput from '../DynamicPhoneInput';
import CheckBoxField from '../CheckBoxField';
import DateBox from '../DateBox';
import FieldError from '../FieldError';
import styles from './FormSection.module.scss';
import { setProperty } from '../../utils/helper';
import Footnote from '../Footnote';
import Label from '../Label';

const phoneRules = { 'X': /[02-9]/ };
const maxDate = new Date();

const FormSection = ({ data, readOnly, section, handleValueChange }) => {
  const [invalidFocus, setInvalidFocus] = useState(false);
  const isInvalid = section.isValid && !section.isValid(data);
  let Component;

  if (section.component) Component = section.component;

  const onValueChanged = (e, field) => {
    if (!e.event) return;
    handleValueChange(setProperty(data, field, e.value));
  };

  const onPhoneValueChanged = (value, field) => {
    handleValueChange(setProperty(data, field, value));
  };

  const handleMouseEnter = () => {
    setInvalidFocus(true);
  };

  const handleMouseLeave = () => {
    setInvalidFocus(false);
  };

  const getInvalidText = (invalidText ) => {
    if (typeof invalidText === 'function') {
      return invalidText(data);
    }
    else {
      return invalidText;
    }
  }

  const renderField = (field, data) => {
    const propertyValue = _.get(data, field.key);
    const propertyValue2 = _.get(data, field.key2);
    const isRequired = typeof field.isRequired === 'function' ? field.isRequired(data) : field.isRequired;
    const isReadOnly = typeof field.readOnly === 'function' ? field.readOnly(data) : field.readOnly;
    const hasMoreInfo = typeof field.moreInfo === 'function' ? field.moreInfo(data) : field.moreInfo;
    let options = typeof field.options === 'function' ? field.options(data): field.options || [];

    switch (field.type) {
      case fieldTypes.TEXTBOX:
        return <TextBox
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'Enter text here.'}
          showClearButton
          readOnly={readOnly || isReadOnly}
          maxLength={field.maxLength || 75}
          moreInfo={hasMoreInfo}
          isValid={field.isValid && field.isValid(data)}
          errorText={field.invalidText}
          isRequired={isRequired}
          disabled={data[field.disabledBy]}
          value={data[field.disabledBy] ? 'N/A' : propertyValue}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.NUMBERBOX:
        return <NumberBox
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'XX'}
          format={field.format || '#,##0'}
          readOnly={readOnly || isReadOnly}
          moreInfo={hasMoreInfo}
          max={field.max}
          showClearButton
          showSpinButtons
          isRequired={isRequired}
          isValid={field.isValid && field.isValid(data)}
          errorText={field.invalidText}
          disabled={data[field.disabledBy]}
          value={data[field.disabledBy] ? 'N/A' : propertyValue}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.TEXTAREA:
        return <TextArea
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'Enter text here.'}
          readOnly={readOnly || isReadOnly}
          autoResizeEnabled
          moreInfo={hasMoreInfo}
          isRequired={isRequired}
          disabled={data[field.disabledBy]}
          value={data[field.disabledBy] ? field.disabledText || 'N/A' : propertyValue}
          minHeight={80}
          maxLength={field.maxLength || 1500}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.SELECT:
        return <SelectBox
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'Select'}
          readOnly={readOnly || isReadOnly}
          isRequired={isRequired}
          disabled={data[field.disabledBy]}
          items={data[field.disabledBy] ? ['N/A'] : options}
          value={data[field.disabledBy] ? 'N/A' : propertyValue}
          valueExpr={field.valueExpr}
          displayExpr={field.displayExpr}
          moreInfo={hasMoreInfo}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.DATEBOX:
        return <DateBox
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'MM/DD/YYYY'}
          showClearButton
          type='date'
          readOnly={readOnly || isReadOnly}
          moreInfo={hasMoreInfo}
          max={field.maxDate || maxDate}
          isRequired={isRequired}
          isValid={field.isValid && field.isValid(data)}
          errorText={field.invalidText || errors.INCOMPLETE_DATA}
          value={propertyValue}
          onValueChanged={e => onValueChanged(e, field.key)} 
        />;
      case fieldTypes.TEXTBOX_PHONE:
        return <TextBoxWithoutTrim
          label={field.label}
          labelIcon={field.labelIcon}
          name={field.key}
          placeholder={data[field.disabledBy] ? 'N/A' : field.placeholder || 'Enter text here.'}
          maskRules={phoneRules}
          showClearButton
          readOnly={readOnly || isReadOnly}
          maxLength={field.maxLength || 19}
          moreInfo={hasMoreInfo}
          isValid={field.isValid && field.isValid(data)}
          errorText={field.invalidText}
          isRequired={isRequired}
          disabled={data[field.disabledBy]}
          value={data[field.disabledBy] ? 'N/A' : propertyValue}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.PHONE:
        return <DynamicPhoneInput
            label={field.label || 'Phone'}
            labelIcon={field.labelIcon}
            countryCodeField={field.key2}
            readOnly={readOnly || isReadOnly}
            isRequired={isRequired}
            disabled={data[field.disabledBy]}
            phoneNumberField={field.key}
            countryCodeValue={propertyValue2}
            phoneValue={propertyValue}
            moreInfo={hasMoreInfo}
            onValueChanged={onPhoneValueChanged}
          />;
      case fieldTypes.CHECKBOX:
        return <CheckBoxField
          className={field.verticalAlign && styles.verticalAlign}
          readOnly={readOnly || isReadOnly}
          value={propertyValue}
          text={field.text}
          size={field.size}
          moreInfo={hasMoreInfo}
          onValueChanged={e => onValueChanged(e, field.key)}
        />;
      case fieldTypes.LABEL:
        return field.displayWhenTrue ? data[field.displayWhenTrue] && <Label title={field.text} /> : <Label title={field.text} />;
      default:
        break;
      }
  };

  return <div className={styles.container}>
    <div className={cn({
          [styles.incompleteTitle]: isInvalid
        })}
        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {section.title && <Title className={styles.title} title={section.title} type='h2' />}
      {isInvalid && <FieldError error={getInvalidText(section.invalidText)} isFocus={invalidFocus} />}
    </div>
    {section.subTitle && <p>{section.subTitle}</p>}
    {section.component && <Component />}
    {section.fields && <div className={styles.innerGrid}>
      {(section.fields && section.fields.length > 0) && section.fields.map((field, i) => <div key={`field${i}`} className={field.colSpan && styles[`col${field.colSpan}`]}>
        {renderField(field, data)}
        {field.footNote && <Footnote text={field.footNote} />}
      </div>)}
    </div>}
  </div>;
};

FormSection.propTypes = {
  data: PropTypes.object,
  section: PropTypes.object,
  handleValueChange: PropTypes.func,
  readOnly: PropTypes.bool
};

export default FormSection;
