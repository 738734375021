import React from 'react';
import { fieldTypes, reportingStandards, urls } from '../index';
import { isDateWithinLast90Days, isDateWithinLastOneYear, isSharesOutstandingLessThanAuthorized, isTierPink } from '../../utils/helper';
import { isNumber } from '../../utils/validations';

const NINETY_DAYS = 'Date must be within the last 90 days.';
const ONE_YEAR = 'Date must be within one year.';

export const formSections = [
    {
        title: 'Share Information',
        fields: [
            {
                type: fieldTypes.NUMBERBOX,
                label: 'Shares Authorized',
                labelIcon: ['fas', 'shield-halved'],
                key: 'authorizedShares',
                colSpan: 4,
                disabledBy: 'unlimitedAuthorizedShares',
                isRequired: data => !data.unlimitedAuthorizedShares,
                isValid: data => data.unlimitedAuthorizedShares ? true : isNumber(data.authorizedShares)
            },
            {
                type: fieldTypes.DATEBOX,
                label: 'As of',
                key: 'authorizedSharesAsOfDate',
                colSpan: 2,
                moreInfo: NINETY_DAYS,
                isRequired: true,
                isValid: data => {
                    return isDateWithinLast90Days(data.authorizedSharesAsOfDate);
                },
                invalidText: NINETY_DAYS
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'Unlimited Number of Shares Authorized',
                key: 'unlimitedAuthorizedShares',
                size: 'small',
                colSpan: 6
            },
            {
                type: fieldTypes.NUMBERBOX,
                label: 'Total Shares Outstanding',
                labelIcon: ['fas', 'shield-halved'],
                key: 'outstandingShares',
                colSpan: 4,
                isRequired: true,
                isValid: data => isSharesOutstandingLessThanAuthorized(data) && isNumber(data.outstandingShares),
                invalidText: 'Shares Outstanding cannot be greater than Number of Shares Authorized.'
            },
            {
                type: fieldTypes.DATEBOX,
                label: 'As of',
                key: 'outstandingSharesAsOfDate',
                colSpan: 2,
                moreInfo: NINETY_DAYS,
                isRequired: true,
                isValid: data => {
                    return isDateWithinLast90Days(data.outstandingSharesAsOfDate);
                },
                invalidText: NINETY_DAYS
            },
            {
                type: fieldTypes.NUMBERBOX,
                label: 'Beneficial Shareholders at least 100 shares',
                key: 'roundLotShareholders',
                max: 99999999,
                colSpan: 4,
                isRequired: data => data.roundLotShareholdersDate || (isTierPink(data.companyTier) ? false : data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) && !data.edgarFilingStatus.includes(reportingStandards.BANK)),
                isValid: data => !isTierPink(data.companyTier) && data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) ? isNumber(data.roundLotShareholders) : true,
                footNote: () => <>
                    Beneficial Shareholder means any person who, directly or indirectly has or shares voting power of such security or investment power, which includes the power to dispose, or to direct the disposition of, such security.
                    <br/>
                    <a href={urls.GLOSSARY_BENEFICIAL_SHAREHOLDER} target='_blank' rel='noopener noreferrer'>See Beneficial Shareholders definition</a></>
            },
            {
                type: fieldTypes.DATEBOX,
                label: 'As of',
                key: 'roundLotShareholdersDate',
                colSpan: 2,
                moreInfo: ONE_YEAR,
                isRequired: data => data.roundLotShareholders || (isTierPink(data.companyTier) ? false : data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) && !data.edgarFilingStatus.includes(reportingStandards.BANK)),
                isValid: data => data.roundLotShareholdersDate ? isDateWithinLastOneYear(data.roundLotShareholdersDate) : true,
                invalidText: ONE_YEAR
            },
            {
                type: fieldTypes.NUMBERBOX,
                label: 'Par Value',
                key: 'parValue',
                colSpan: 4,
                format: '#0.#####',
                disabledBy: 'noParValue'
            },
            {
                type: fieldTypes.CHECKBOX,
                text: 'No Par Value',
                key: 'noParValue',
                size: 'small',
                verticalAlign: true,
                colSpan: 2
            },
            {
                type: fieldTypes.NUMBERBOX,
                label: 'Public Float',
                key: 'publicFloat',
                colSpan: 4,
                isRequired: data => data.publicFloatAsOfDate || (isTierPink(data.companyTier) ? false : data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) && !data.edgarFilingStatus.includes(reportingStandards.BANK)),
                isValid: data => !isTierPink(data.companyTier) && data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) ? isNumber(data.roundLotShareholders) : true,
                footNote: () => <>
                    Public Float refers to the total number of unrestricted shares not held directly or indirectly by an officer, director, any person who is the beneficial owner of more than 10 percent of the total shares outstanding (a “10% Control Person”), or any Affiliates thereof, or any Family Members of officers, directors and 10% Control Persons.
                    <br />
                    <a href={urls.GLOSSARY_PUBLIC_FLOAT} target='_blank' rel='noopener noreferrer'>See Public Float definition</a>
                </>
            },
            {
                type: fieldTypes.DATEBOX,
                label: 'As of',
                key: 'publicFloatAsOfDate',
                colSpan: 2,
                moreInfo: ONE_YEAR,
                isRequired: data => data.publicFloat || (isTierPink(data.companyTier) ? false : data.edgarFilingStatus && !data.edgarFilingStatus.includes(reportingStandards.INTERNATIONAL) && !data.edgarFilingStatus.includes(reportingStandards.BANK)),
                isValid: data => data.publicFloatAsOfDate ? isDateWithinLastOneYear(data.publicFloatAsOfDate) : true,
                invalidText: ONE_YEAR
            },
        ]
    }
];
